import Network from '@/services/network';

export default function handleProjects() {
    const network = new Network;

    const fetchFD6List = (query) => {
        return network.api('get', '/procurement/fd6' + query);
    }

    const fetchParties = (query) => {
        return network.api('get', '/contact-profile-names' + query);
    }

    const fetchRoles = (query) => {
        return network.api('get', '/roles' + query);
    }

    const createNewFD6 = (query, data) => {
        return network.api('post', `/procurement/fd6${query}`, data);
    }

    const fetchFD6s = (query) => {
        return network.api('get', '/procurement/fd6/get' + query);
    }

    const fetchFD6Form = (id, query) => {
        return network.api('get', `/procurement/fd6/${id}/edit${query}`);
    }

    const createFD6AccountGroup = (query, data) => {
        return network.api('post', '/procurement/fd6/account/group' + query, data);
    }

    const createFD6Account = (query, data) => {
        return network.api('post', '/procurement/fd6/account' + query, data);
    }

    const updateFD6AccountGroup = (id, query, data) => {
        return network.api('patch', '/procurement/fd6/account/group/' + id + query, data);
    }

    const updateFD6Account = (id, query, data) => {
        return network.api('patch', '/procurement/fd6/account/' + id + query, data);
    }

    const fetchFD6Account = (id, query) => {
        return network.api('get', `/procurement/fd6/account/${id + query}`);
    }

    const fetchFD6AccountTree = (id, query) => {
        return network.api('get', `/procurement/fd6/${id}/account${query}`);
    }

    const fetchFD6Accounts = (id, query) => {
        return network.api('get', `/procurement/fd6/${id}/account/all${query}`);
    }

    const fetchFD6Groups = (id, query) => {
        return network.api('get', `/procurement/fd6/${id}/account/groups${query}&is_flatten=1`);
    }

    const fetchFD6Donors = (id, query) => {
        return network.api('get', `/procurement/fd6/${id}/get/donors${query}`);
    }

    const updateFD6 = (id, query, data) => {
        return network.api('put', `/procurement/fd6/${id}` + query, data);
    }

    const fetchAccountsAndItemsByFD6Id = (id, query) => {
        return network.api('get', `/procurement/fd6/accounts-and-items-by-fd6-id/${id}${query}`);
    }

    // FD6 Budget
    const createNewFD6Budget = (data, query) => {
        return network.api('post', `/procurement/fd6-budget${query}`, data);
    }

    const updateFD6Budget = (id, data, query) => {
        return network.api('put', `/procurement/fd6-budget/${id + query}`, data);
    }

    const fetchAccountTree = (id, query) => {
        return network.api('get', `/procurement/fd6-budget/accounts/${id + query}`);
    }

    const fetchFD6Budget = (id, query) => {
        return network.api('get', `/procurement/fd6-budget/${id + query}`);
    }

    const fetchFD6BudgetGeneral = (budgetId, id, query) => {
        return network.api('get', `/procurement/fd6-budget/${budgetId}/general/${id + query}`);
    }

    const fetchAccount = (budgetId, id, query) => {
        return network.api('get', `/procurement/fd6-budget/${budgetId}/account/${id + query}`);
    }

    const storeFD6BudgetGeneral = (id, data, query) => {
        return network.api('post', `/procurement/fd6-budget/${id}/general${query}`, data);
    }

    const updateFD6BudgetGeneral = (id, generalId, data, query) => {
        return network.api('post', `/procurement/fd6-budget/${id}/general/${generalId + query}`, data);
    }

    const fetchFD6BudgetMasters = (query) => {
        return network.api('get', `/procurement/fd6-budget${query}`);
    }

    const fetchDonorFD6Accounts = (id, query) => {
        return network.api('get', `/procurement/fd6-budget/${id}/activities${query}`);
    }

    const fetchDonorBudgets = (id, query) => {
        return network.api('get', `/procurement/fd6-budget/donor/${id}/get-budget${query}`);
    }

    const fetchActualCost = (id, query) => {
        return network.api('get', `/procurement/fd6-budget/fd6-account/${id}/cost${query}`);
    }

    const fetchBudgetVsActual = (fd6BudgetMaster, query) => {
        return network.api('get', `/procurement/fd6-budget/get/budget-vs-actual/${fd6BudgetMaster}${query}`);
    }

    const fetchDonorBudgetVsActual = (fd6Master, query) => {
        return network.api('get', `/procurement/fd6-budget/get/donor-bva/${fd6Master}${query}`);
    }

    const fetchNewFD6BudgetInterval = (fd6BudgetMaster, query) => {
        return network.api('get', `/procurement/fd6-budget/get/interval/${fd6BudgetMaster}${query}`);
    }

    const fetchFD6AccountsAll = (query) => {
        return network.api('get', `/procurement/fd6/accounts${query}`);
    }

    const fetchFD6BudgetBreakDowns = (id, query) => {
        return network.api('get', `/procurement/fd6-budget/get-budget-breakdowns/${id}${query}`);
    }

    const fetchAllFD6Donors = (query) => {
        return network.api('get', `/procurement/fd6/get-all-donors${query}`);
    }

    const fetchFd6BudgetGeneral = (fd6BudgetMasterId, query) => {
        return network.api('get', `/procurement/fd6-budget/get-budget-general/${fd6BudgetMasterId}${query}`);
    }

    return {
        fetchFD6List,
        fetchParties,
        fetchRoles,
        createNewFD6,
        fetchFD6s,
        createFD6AccountGroup,
        createFD6Account,
        updateFD6AccountGroup,
        updateFD6Account,
        fetchFD6Form,
        fetchFD6AccountTree,
        fetchFD6Accounts,
        fetchFD6Account,
        fetchFD6Groups,
        updateFD6,
        fetchFD6Donors,
        fetchAccountsAndItemsByFD6Id,

        createNewFD6Budget,
        updateFD6Budget,
        fetchAccountTree,
        fetchFD6Budget,
        fetchFD6BudgetGeneral,
        fetchAccount,
        storeFD6BudgetGeneral,
        updateFD6BudgetGeneral,
        fetchFD6BudgetMasters,
        fetchDonorFD6Accounts,
        fetchActualCost,
        fetchDonorBudgets,
        fetchBudgetVsActual,
        fetchDonorBudgetVsActual,
        fetchNewFD6BudgetInterval,
        fetchFD6AccountsAll,

        fetchFD6BudgetBreakDowns,
        fetchAllFD6Donors,
        fetchFd6BudgetGeneral,
    }
}